@import "_grid";
@import "_settings-color";

/*
containers
 */
.container,
[class*="container-"] {
  @include column-container;
  @include site-max-width;
  width: 100%;

  .box-title,
  .box-pricing {
    padding: $gutter-width / 2;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 33em;
    @include column(1, 1);

    @media all and (min-width: $break-l) {
      @include column(8, 12);
      max-width: none;
    }
  }

  .testimonial {
    quotes: "“" "”" "“" "”";
    font-family: "Lora", serif;
    font-size: larger;
    text-indent: -15px;
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  @supports ( hanging-punctuation: first) {
    .testimonial {
      text-indent: 0;
      hanging-punctuation: first;
    }
  }

  .testimonial:before {
    color: #5b5def;
    content: open-quote;
    font-size: 3em;
    line-height: 0.1em;
    margin-right: 0.1em;
    vertical-align: -0.4em;
  }

  .title-small {
    padding: $gutter-width / 2;
    //margin-left: auto;
    //margin-right: auto;
    //text-align: center;
    max-width: 33em;
    @include column(1, 1);

    h1 {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    h3 {
      color: $color-secondary;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  .box-title-small {
    padding: $gutter-width / 2;
    padding-top: $gutter-width;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 33em;
    @include column(1, 1);

    h1 {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    h3 {
      color: $color-secondary;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  .button-action {
      text-align: center;
      overflow: visible;
      font-family: "Poppins", sans-serif;
      font-size: 1em;
      height: initial;
      min-height: 0 !important;
      border: none;
      line-height: 1ex;
      display: inline-block;
      padding: 1.5em;
      color: white;
      border-radius: 0.25em;
      text-decoration: none;
      cursor: pointer;
      text-shadow: none;

      background-image: $gradient-main;

      &:not(.text-small) {
        font-weight: 700;
      }
  }

  .button-action-small {
    text-align: center;
    overflow: visible;
    font-family: "Poppins", sans-serif;
    font-size: .75em;
    height: initial;
    min-height: 0 !important;
    border: none;
    line-height: 1ex;
    display: inline-block;
    padding: 1em;
    color: white;
    border-radius: 0.25em;
    text-decoration: none;
    cursor: pointer;
    text-shadow: none;

    background-image: $gradient-main;

    &:not(.text-small) {
      font-weight: 700;
    }
  }

  .box-card {
    @include column(1, 1);
    padding: $gutter-width / 2;
    overflow: visible;
    max-width: 33em;

    > * {
      height: 100%;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 2);
      overflow: visible;
    }
  }

  .box-card-light {
    @include column(1, 1);
    padding: $gutter-width;
    overflow: visible;
    max-width: 50em;

    > * {
      height: 100%;
    }

    ul {
      padding-left: 0;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 3);
      overflow: visible;
    }
  }

  .box-card-large {
    @include column(1, 1);
    padding: 0;
    overflow: visible;
    max-width: 50em;
    border-radius: 5px;
    border-color: #dddddd;
    border-width: 3px;
    border-style: solid;
    border-top-width: 20px;

    > * {
      height: 100%;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 1.5);
      overflow: visible;
    }
    .element-card-default {
      padding: 0;
    }
  }

  .box-card-small {
    @include column(1, 1);
    padding: $gutter-width / 2;
    overflow: visible;
    max-width: 33em;

    > * {
      height: 100%;
      justify-content: flex-start;
    }

    p {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }

    ul {
      padding-left: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
    }

    h3 {
      text-align: center;
    }

    .header {
      padding-top: 0.2em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.8em;

      img {
        width: 100%;
        max-width: max-content;
        height: auto;
        display: block;
        margin: auto;
      }
    }

    .content {
      padding-top: 1em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5em;
      font-size: smaller;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 4);
      overflow: visible;
    }
  }

  .box-card-left {
    @include column(1, 1);
    padding: $gutter-width / 2;
    overflow: visible;
    max-width: 33em;

    > * {
      height: 100%;
      justify-content: flex-start;
    }

    p {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }

    ul {
      padding-left: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0;
      margin-inline-start: 15px;
      margin-inline-end: 15px;
    }

    h4 {
      margin-top: 0;
      font-size: smaller;
      margin-inline-start: 15px;
      margin-inline-end: 15px;
    }

    .header {
      padding-top: 0.2em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.8em;

      img {
        width: 100%;
        max-width: max-content;
        height: auto;
        display: block;
        margin: auto;
      }
    }

    .content {
      padding-top: 1em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5em;
      font-size: smaller;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 2.7);
      overflow: visible;
    }
  }

  .box-card-licence {
    @include column(1, 1);
    padding: $gutter-width / 2;
    overflow: visible;
    max-width: 33em;

    > * {
      height: 100%;
      justify-content: space-between;
    }

    p {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }

    ul {
      padding-left: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0;
      margin-inline-start: 15px;
      margin-inline-end: 15px;
    }

    h4 {
      margin-top: 0;
      font-size: smaller;
      margin-inline-start: 15px;
      margin-inline-end: 15px;
    }

    .header {
      padding-top: 0.2em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.8em;

      img {
        width: 100%;
        max-width: max-content;
        height: auto;
        display: block;
        margin: auto;
      }
    }

    .content {
      padding-top: 1em;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1em;
      font-size: smaller;
    }

    @media all and (min-width: $break-l) {
      @include column(1, 3);
      overflow: visible;
    }
  }

  .box-article {
    padding: $gutter-width / 2;
    margin-left: auto;
    margin-right: auto;
    max-width: 33em;
    @include column(1, 1);

    @media all and (min-width: $break-l) {
      @include column(8, 12);
      max-width: none;
    }
  }
}

.container-card,
[class*="container-card-"] {
  @include site-max-width;
  @include container-content-centred;
  overflow: visible;
}

.container-button {
  overflow: visible;
}
