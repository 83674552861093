@import "_settings-parameters";

@mixin site-max-width {
  max-width: $max-width;
  position: relative;
  margin: auto;
}
//@mixin site-max-width-small {
//  max-width: 50em;
//  position: relative;
//  margin: auto;
//}

@mixin column-container() {
  overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
  display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  align-items: stretch;
  position: relative;
  box-sizing: border-box;

  > * {
    flex: none;
  }
}

@mixin row-container {
  @include column-container;
  flex-direction: column;
}

@mixin container-content-centred {
  justify-content: center;
}
@mixin container-content-justified {
  justify-content: space-between;
}

@mixin column($column-number, $column-division: 24) {
  overflow: hidden;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100% / $column-division * $column-number;
}

@mixin column-skip($column-number, $column-division: 24) {
  margin-left: 100% / $column-division * $column-number;
}

@mixin gutter {
  padding-left: $gutter-width / 2;
  padding-right: $gutter-width / 2;
}

@mixin gutter-horizontal {
  padding-top: $gutter-width / 2;
  padding-bottom: $gutter-width / 2;
}