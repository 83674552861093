@import "_settings-parameters";
@import "_settings-color";
@import "_settings-fonts";

.element-form {
  position: relative;

  [name="submit"] {
    //min-width: 10em;
  }
  .hidden-button {
    visibility: hidden;
    //min-width: 10em;
  }


  > *:first-child:not(fieldset) {
    padding-top: 0;
  }
  > fieldset:first-child {
    margin-top: -1em;
  }
  > *:last-child {
    padding-bottom: 0 !important;
  }

  fieldset {
    border: none;
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: $gutter-width 0 0;
    box-shadow: 0 20px 20px -5px rgba(0,0,0,0.02), 0 2px 6px 0 rgba(0,0,0,0.02);
    opacity: 1;

    + fieldset {
      margin-top: .5em;
    }

    input,
    textarea {
      -webkit-appearance: none;
      border: none;
      margin: 0;
      padding: $gutter-width / 2;
      display: block;
      width: 100%;
      box-sizing: border-box;
      color: $color-main;
      outline: $color-main 1px solid;
      outline-offset: -1px;
      &:focus {
        outline-color: $color-gray;
      }
      &[required] {
        + label {
          > span:after {
            content: "*";
            color: $color-error;
          }
        }
      }
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: $gutter-width;
      pointer-events: none;
      user-select: none;
      box-sizing: border-box;
      span {
        color: $color-gray;
        position: relative;
        display: inline-block;
        height: 100%;
        top: 0;
        left: 0;
        padding: $gutter-width / 2;
        box-sizing: border-box;
        line-height: normal;
        transform: translate3d(0, 0, 0);
        -webkit-transition: transform 250ms ease-in-out;
        -moz-transition: transform 250ms ease-in-out;
        -ms-transition: transform 250ms ease-in-out;
        -o-transition: transform 250ms ease-in-out;
        &:before {
          @include font-s;
          margin: 0;
          color: $color-main;
          content: attr(data-content);
          position: absolute;
          top: -100%;
          left: 0;
          transform: translate3d(0, -100%, 0);
          -webkit-transition: opacity 500ms ease-in-out;
          -moz-transition: opacity 500ms ease-in-out;
          -ms-transition: opacity 500ms ease-in-out;
          -o-transition: opacity 500ms ease-in-out;
          transition: opacity 500ms ease-in-out;
          opacity: 0;
        }
      }
    }

    &.focused, &.completed {
      span {
        transform: translate3d(0, 100%, 0);
        &:before {
          opacity: 1;
        }
      }
    }
    &.not-empty {
      input {
        border-color: $color-main;
      }
    }
  }

  .container-button {
    padding-top: $gutter-width * 2;
    padding-bottom: $gutter-width * 2;
    @include container-content-centred;
  }


  .button-waiting,
  .button-valid {
    display: none;
    position: relative;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 20px;
      border-radius: 100%;
      margin: 0 auto;

      span {
        display: inline-block;
        width: 5px;
        height: 20px;
        background-color: white;

        &:nth-child(1) {
          animation: grow 1s ease-in-out infinite;
        }
        &:nth-child(2) {
          animation: grow 1s ease-in-out 0.15s infinite;
        }
        &:nth-child(3) {
          animation: grow 1s ease-in-out 0.30s infinite;
        }
        &:nth-child(4) {
          animation: grow 1s ease-in-out 0.45s infinite;
        }
      }
    }
  }

  .button-valid {
    background-color: $color-secondary;
  }

  .form-validation-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    .form-validation-text {
      user-select: none;
      position: absolute;
      left: 0;
      top: 33.33333%;
      padding-left: $gutter-width;
      padding-right: $gutter-width;
      -webkit-transform: translate(0, -33.33333%);
      -moz-transform: translate(0, -33.33333%);
      -ms-transform: translate(0, -33.33333%);
      -o-transform: translate(0, -33.33333%);
      transform: translate(0, -33.33333%);
    }
  }

  &.send {
    input[type="submit"] {
      display: none;
    }

    .button-waiting {
      display: block;
    }
  }

  &.sending {
    .button-waiting {
      display: none;
    }

    .button-valid {
      visibility: hidden;
      display: block;
    }

    fieldset {
      -webkit-transition: opacity 500ms;
      -moz-transition: opacity 500ms;
      -ms-transition: opacity 500ms;
      -o-transition: opacity 500ms;
      transition: opacity 500ms;
      opacity: 0.15;
    }

    .form-validation-container {
      display: block;
    }
  }
}

@keyframes grow{
  0%, 100%{
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50%{
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}