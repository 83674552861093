@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/PoppinsLatin-Regular.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/PoppinsLatin-Italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/PoppinsLatin-SemiBold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/PoppinsLatin-SemiBoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Lora";
  src: url("../fonts/lora/Lora-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}





