$color-main: #6047de;
$color-main-dark: #160959;
$color-main-light: #f9f9fb;

$color-main-alternate: #4F77F0;

$color-secondary: #20D6D6;

$color-ternary: #E34D4D;

$color-quaternary: #FFB042;

$color-quaternary-alternate: #FF763D;

$color-gray: #a0a0a0;

$background-color-gray-opacity: rgba($color-gray, 0.15);

$gradient-main: linear-gradient(-135deg, $color-main-alternate 0%, $color-main 100%);

$gradient-quaternary: linear-gradient(-135deg, $color-quaternary 0%, $color-quaternary-alternate 100%);

$box-shadow-main: 0 10px 60px -10px rgba($color-main,0.20);
$box-shadow-main-dark: 0 0 15px 0 rgba($color-main-dark,0.40), 0 10px 60px -10px rgba($color-main,0.40);
$box-shadow-main-alternate: 0 0 5px 0 white, 0 0 40px 0 rgba($color-main-alternate,0.40);

/**/
$color-error: $color-ternary;
