@import "_grid";

#header{
  overflow: hidden;

  .container {
    overflow: visible;
    padding-top: 2em;

    @media all and (min-width: $break-l) {
      height: 100vh;
      min-height: 600px;
      padding-bottom: 0;
    }

    @media all and (min-width: $break-xl) {
      min-height: 675px;
    }
  }

  .header-content, .header-content-large {
    @include column(1, 1);
    @include gutter;

    @media all and (min-width: $break-l) {
      @include column(1, 2);
    }

    .header-text {
      position: relative;
      padding-top: 2rem;

      @media all and (min-width: $break-l) {
        padding-top: 0;
        top: 50%;
        transform: translate(0, -100%);
      }

      > *:not(:last-child) {
        margin: 0 0 1rem;
      }

      > [class*=button] {
        margin-top: 1.5rem;
      }
    }
  }

  .header-content-large {
     width: 100%;
  }

  .header-content-small {
    width: 30%;
  }

  #header-background-container {
    @include column(1, 1);
    overflow: visible;

    @media all and (min-width: $break-l) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  #header-background {
    width: 700px;
    max-width: 150%;
    height: auto;
    display: block;
    position: relative;
    margin: 0 auto;
    transform: translate(0, -10%);

    @media all and (min-width: $break-l) {
      position: absolute;
      top: 50%;
      right: 0;
      width: (100% / 12 * 10);
      height: auto;
      transform: translate(25%, -50%);
    }

    @media all and (min-width: $break-xl) {
      width: auto;
      height: auto;
      transform: translate(35%, -50%);
    }
  }
}
