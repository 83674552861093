@import "font-loader";
@import "init";

@import "container";
@import "container-card-princing";

@import "section";
@import "text";
@import "top-menu";
@import "ui";

@import "element-card";
@import "element-card-chart";
@import "element-form";
@import "element-history";

/* site style*/
@import "site-section-eap";
@import "site-section-footer";
@import "site-section-header";
@import "site-section-motivations";
@import "site-section-features";
@import "site-section-samples";


/* page style*/
@import "page-response";
@import "page-pricing";

/* stripe style */
@import "stripe-style";


.intro-text-cache-container {
  position: relative;
  overflow: hidden;
  transform: translate(0, -150%);

  -webkit-animation: text-intro-container 3s cubic-bezier(0.5, 0, 0, 1);
       -o-animation: text-intro-container 3s cubic-bezier(0.5, 0, 0, 1);
          animation: text-intro-container 3s cubic-bezier(0.5, 0, 0, 1);

  > * {
    overflow: hidden;
    position: relative;
    transform: translate(0, 100%);

    -webkit-animation: text-intro 3s cubic-bezier(0.5, 0, 0, 1);
         -o-animation: text-intro 3s cubic-bezier(0.5, 0, 0, 1);
            animation: text-intro 3s cubic-bezier(0.5, 0, 0, 1);
  }

  &.no-animated {
    -webkit-animation: none;
         -o-animation: none;
            animation: none;
    transform: none;
    > * {
      -webkit-animation: none;
           -o-animation: none;
              animation: none;
      transform: none;
    }
  }
}

.intro-to-up {
  transform: translate(0, -8em);


  -webkit-animation: to-up 2s cubic-bezier(0.5, 0, 0, 1);
       -o-animation: to-up 2s cubic-bezier(0.5, 0, 0, 1);
          animation: to-up 2s cubic-bezier(0.5, 0, 0, 1);
}

@keyframes text-intro-container {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -150%);
  }
}


@keyframes text-intro {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes to-up {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -8em);
  }
}



#header {
  #header-background.animated {
    -webkit-filter: blur(2px);
    filter: blur(2px);

    transform: translate(37%, -50%);

    -webkit-animation: blur-intro 3s cubic-bezier(0.5, 0, 0, 1);
    -o-animation: blur-intro 3s cubic-bezier(0.5, 0, 0, 1);
    animation: blur-intro 3s cubic-bezier(0.5, 0, 0, 1);
  }
}


@keyframes blur-intro {
  0% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    transform: translate(35%, -50%) scale(1);
  }

  75% {
    -webkit-filter: blur(2px);
            filter: blur(2px);
  }

  100% {
    transform: translate(37%, -50%) ;
  }
}