@import "_grid";

#top-menu {
  @include column-container;
  @include container-content-justified;

  @include gutter-horizontal;

  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100000;
  width: 100%;
  -webkit-transition: padding 250ms ease-in-out;
  -moz-transition: padding 250ms ease-in-out;
  -ms-transition: padding 250ms ease-in-out;
  -o-transition: padding 250ms ease-in-out;
  transition: padding 250ms ease-in-out;

  .container {
    overflow: visible;
  }

  &.minimize {
    padding-top: 0;
    padding-bottom: 0;
  }

  .rm-screen-s {
    display: none;

    @media all and (min-width: 650px) {
      display: block;
    }
  }

  // hamburger menu
  .rm-screen-l {
    display: block;

    @media all and (min-width: 650px) {
      display: none;
    }

    #menuToggle {
      display: block;
      position: absolute;
      top: 7px;
      right: 15px;

      z-index: 1;

      -webkit-user-select: none;
      user-select: none;
    }

    #menuToggle a {
      text-decoration: none;
      color: $color-main-dark;

      transition: color 0.3s ease;
    }

    #menuToggle a:hover {
      color: $color-main;
    }


    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */

      -webkit-touch-callout: none;
    }

    /*
     * Just a quick hamburger
     */
    #menuToggle span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;

      background: $color-main;
      border-radius: 3px;

      z-index: 1;

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    /*
     * Transform all the slices of hamburger
     * into a crossmark.
     */
    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: $color-main-dark;
    }

    /*
     * But let's hide the middle one.
     */
    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
     * Ohyeah and the last one should go the other direction
     */
    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }

    /*
     * Make this absolute positioned
     * at the top left of the screen
     */
    #menu {
      position: absolute;
      width: 200px;
      left: 60px;
      padding: 50px;
      padding-top: 72px;

      background: #f9f9fb;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */

      transform-origin: 0% 0%;
      transform: none;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }

    #menu li {
      padding: 15px 0;
      font-size: 22px;
      margin-top: 0;
    }

    /*
     * And let's slide it in from the left
     */
    #menuToggle input:checked ~ ul {
      transform: translate(-100%, 0);
    }
  }
}