@import "_settings-color";

.section,
[class*=section-] {
  padding-top: 3rem;
  padding-bottom: 7rem;
  position: relative;
  overflow: hidden;
}

.section-small,
[class*=section-small] {
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;

  .box-card {
    margin-left: auto;
    margin-right: auto;
  }
}

.section-small-alternate {
  background-color: $color-main-light;
}

.section-small-dark {
  background-color: $color-main-dark;
  color: white;
}

.section-alternate {
  background-color: $color-main-light;
}

.section-dark {
  background-color: $color-main-dark;
  color: white;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > * {
    position: absolute;
  }
}