@import "_settings-color";
@import "_settings-fonts";

.identity-name {
  font-weight: 700;
  .extension {
    font-weight: 500;
  }
}

.text-light {
  color: $color-gray;
}

.text-highlighting {
  color: $color-main;
}

a {
  color: #5B5DEF;
  font-weight: 400;
  text-decoration: none;
}

.text-small {
  @include font-s;
}

.text-pricing,
[class*="text-pricing"] {
  position: relative;

  .text-pricing-money {
    @include font-reg;
    font-size: 0.66666em;
    vertical-align: middle;
  }

  .text-pricing-value {
    vertical-align: middle;
  }

  &:not(.text-pricing-inline) {
    .text-pricing-money {
      position: absolute;
      margin: 0;
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
    }
  }
}