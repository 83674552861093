.box-card-pricing {
  label {
    padding-top: 1em;
  }
}

.oem-license {
  .content {
    min-height: 6.15em;
  }
}

.error {
  font-size: x-small;
  color: red;
}

.element-card-license {
  input {
    width: 100%;
  }
}