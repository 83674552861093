@import "_grid";
@import "_settings-color";

#eap {
  #eap-form {
    margin-top: 6rem;

    //max-width: 33em;
    //-webkit-box-shadow: $box-shadow-main-dark;
    //-moz-box-shadow: $box-shadow-main-dark;
    //box-shadow: $box-shadow-main-dark;
  }

  #bar-chart-first {
    right: 0;
    bottom: 2rem;
  }

  #bar-chart-second {
    top: 35%;
    left: 0;
  }

  #circle-chart {
    top: 10rem;
    right: 10%;
  }

  #circle-purple {
    right: (-170px / 100 * 75 );
    top: (-170px / 100 * 46 );
  }

  #circle-blue-small {
    top: 50px;
    left: (-278px / 100 * 50);
  }

  #circle-blue-large {
    bottom: (-606px / 4);
    left: (-606px / 4);
  }

  #circle-stroke-gradient {
    top: 1rem;
    left: 3%;
  }
}