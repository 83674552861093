@import "_settings-color";
@import "_grid";

#footer {
  background-color: darken($color-main-dark, 8);
  color: rgba($color-main-light, 0.75);
  padding-top: 1rem;
  padding-bottom: $gutter-width;

  .content {
    @include column-container;
    @include gutter-horizontal;
    @include gutter;
  }

  #logo-footer {
    height: 4em;
    width: auto;
  }
}