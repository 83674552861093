@import "_grid";
@import "_settings-color";

[class*=element-card-] {
  @include row-container;
  @include container-content-justified;
  background-color: white;
  padding: $gutter-width;

  .header,
  .content {
    @include column(1, 1);
    padding: $gutter-width;

    > img {
      width: 100%;
      height: auto;
      display: block;
    }

    label {
      display: inline-block;
      min-width: 9em;
      font-size: smaller;
    }

    input, select {
      border-color: $color-main;
      border-radius: 3px;
      border-width: 1px;
      min-height: 1.6em;
      width: 100%;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.coll {
  a {
    color: white;
  }
}

.element-card-default {
  box-shadow: $box-shadow-main;
  color: $color-main-dark;
}
.element-card-license {

  padding-top: 0;
  padding-bottom: 0;

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}