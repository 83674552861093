@import "_settings-fonts";

.stripe-form-container{
  padding-top: 0 !important;
}

.stripe-element{
  padding-top: 0;
}

.stripe-content{
}


.stripe-payment {

  > form {
    text-align: center;
  }

  .stripe-button-el {
    overflow: visible;

  }
  .stripe-button-el > span {
    font-family: "Poppins", sans-serif;
    font-size: 1em;
    height: initial;
    min-height: 0 !important;
    border: none;
    line-height: 1ex;
    display: inline-block;
    padding: 1.5em;
    color: white;
    border-radius: 0.25em;
    text-decoration: none;
    cursor: pointer;
    text-shadow: none;

    background-image: $gradient-main;
    box-shadow: $box-shadow-main-alternate;

    &:not(.text-small) {
      font-weight: 700;
    }
  }
}