@import "_settings-fonts";

body,
html {
  @include font-init;
  min-width: $min-width;
  color: $color-main-dark;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@if($gutter-on-outside) {
  body > main > * {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
  }
}

h1 {
  @include font-xxl;
}

h2 {
  @include font-l;
}

h3 {
  @include font-m;
}

p {
  @include font-reg;
}

ul {
  @include font-reg;

  li {
    margin-top: 1em;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: inherit;
    }
  }
}

input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
}