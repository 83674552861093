@import "_settings-color";

#page-response {
  min-height: 100vh;
  background: darken($color-main-dark, 8);

  > *:not(#footer) {
    background: white;
  }

  #header {
    .container {
      min-height: initial;
      height: auto;
    }

    .header-text {
      padding-top: 2em;
      top: 0;
      transform: none;
    }
  }


  svg#error-icon {
    width: 7em;
    height: auto;
    fill: $color-ternary;
    position: relative;
    left: 50%;
    transform: translate(-50%, 8%);
  }

  svg#success-icon {
    width: 10em;
    height: auto;
    fill: #6b51e2;
    position: relative;
    left: 50%;
    transform: translate(-50%, 8%) scale(-1, 1);
  }

  #eap {
    padding-bottom: 3em;
    overflow: visible;
  }

  #eap #eap-form {
    margin-top: 0;
  }

  #footer {
    //margin-top: -3em;
  }

  &.error-response {
    .container-card-pricing .box-pricing {
      color: $color-ternary;
    }
  }
}
