@import "_settings-color";
@import "_grid";

.element-card-chart,
[class*=element-card-chart] {
  background-color: $color-main;
  //text-align: center;
}

.element-card-chart-secondary {
  background-color: $color-secondary;
}

.element-card-chart-ternary {
  background-color: $color-ternary;
}

.element-card-chart-quaternary {
  background-color: $color-quaternary;
}

.element-card-chart-light {
  background-color: saturate(lighten($color-main, 37), 0);
  .title {
    color: $color-main;
  }
}
