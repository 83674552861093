$html-font-size: 18px;
$gutter-on-outside: true;

$gutter-width: 1em;

$max-width: 60em;
$min-width: 350px;

$break-s: 550px;
$break-l: 920px;
$break-xl: 1080px;