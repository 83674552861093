@import "_settings-color";
@import "_settings-parameters";

@mixin font-init {
  font-family: "Poppins", sans-serif;
  line-height: 1.25em;
  font-weight: 500;
  font-size: 15px;

  @media all and (min-width: $break-s) {
    font-size: $html-font-size;
  }
}

@mixin font-xxl {
  font-size: 3em;
  line-height: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 700;
}

@mixin font-xl {}

@mixin font-l {
  font-size: 2em;
  line-height: 1.125em;
  margin-top: .5em;
  margin-bottom: .5em;
  font-weight: 500;
}

@mixin font-m {
  font-size: 1.25em;
  line-height: 1.25em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 700;
}

@mixin font-reg {
  font-size: 1em;
  line-height: 1.25em;
  margin-top: 1.25em;
  margin-bottom: 1.75em;
  font-weight: 500;
}

@mixin font-s {
  font-size: 0.75em;
  line-height: 1.3333333333em;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  font-weight: 500;
}