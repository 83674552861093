@import "_settings-color";
@import "_settings-fonts";
@import "_grid";

/*
buttons
 */
.button,
[class*=button-] {
  line-height: 1ex;
  display: inline-block;
  padding: 1.5em;
  color: white;
  background-color: $color-main;
  border-radius: 0.25em;
  text-decoration: none;
  cursor: pointer;
  border: none;

  &:not(.text-small) {
    font-weight: 700;
  }
}

.button-transparent {
  color: $color-main-dark;
  background-color: transparent;

  &:hover {
    background-color: $background-color-gray-opacity;
  }
}

.button-alternate {
  background-image: $gradient-main;
  box-shadow: $box-shadow-main-alternate;
}

.button-small {
  padding: 1em;
}

.button-logo {
  padding: 0;
}

.button-rounded {
  border-radius: 2em;
}

/*
menu
 */
menu,
.menu {
  @include column-container;
  align-items: center;
  margin: 0;
  padding: 0;

  li {
    text-decoration: none;
    display: block;
  }

  * {
    margin: 0;
  }

  > * + * {
    margin-left: $gutter-width;
  }
}

.menu-vertical,
[class*="menu-vertical-"] {
  @include row-container;
  margin: 0;
  padding: 0;

  li {
    text-decoration: none;
    display: block;
  }

  * {
    margin: 0;
  }

  > * + * {
    margin-top: $gutter-width / 2;
  }
}

.menu-vertical-with-logo {
  > li:not(.menu-logo) {
    padding-left: 1em;
  }
}

/*
list
 */
ul.list-validation {
  $icon-radius: 30px;

  padding: 0;

  li {
    position: relative;
    list-style: none;
    padding-left: $icon-radius * 2;
    margin-top: 2em;
    margin-bottom: 2em;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: $icon-radius;
      height: $icon-radius;
      background: url("../images/_ui/empty.svg") no-repeat center;
      background-size: 1.5em;
    }

    &.yes {
      &:before {
        background-image: url("../images/_ui/yes.svg");
      }
    }

    &.no {
      &:before {
        background-image: url("../images/_ui/no.svg");
      }
    }
  }
}