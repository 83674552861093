@import "_settings-parameters";

#samples {
  @media all and (max-width: $break-xl){
    padding-top: 0;
  }

  .chart-image-container {
    position: relative;
    overflow: hidden;

    > img {
      position: relative;
      width: 100%;
      height: auto;
      z-index: 10;
    }

    .charts-image-lines {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .charts-image-bottom {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }
  }
}