@import "_settings-color";
@import "_grid";

.element-history,
[class*=element-history-] {
  position: relative;
  overflow: hidden;
  padding-left: $gutter-width * 3;

  ul:last-child,
  ol:last-child,
  li:last-child {
    margin-bottom: 0;
  }

  > *:first-child {
    margin-top: 1em !important;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(-180deg, rgba($color-quaternary, 0) 0%, $color-quaternary 5em, $color-quaternary-alternate 100%);
  }

  ul {
    list-style: none;
    padding: 0;

    ul {
      > li {
        position: relative;
        padding-left: $gutter-width;

        &:before {
          content: '';
          width: 1ex;
          height: 1.25em;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: url("../images/_ui/circle-bullet-point.svg") no-repeat center;
          background-size: 1ex;
        }
      }
    }
  }

  .version {
    color: $color-quaternary;
    position: relative;
    left: -0.1em;
    margin-top: 3em;
  }

  .sub-version {
    margin-top: 3em;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background: linear-gradient(90deg, rgba($color-main, 0) 0%, $color-main 5%, rgba($color-main, 0.25) 30%, rgba($color-main, 0) 100%);
      opacity: 0.75;
      transform: translate(-0.5em, -1.5em);
    }
  }
}

.element-history-next {
  opacity: 0.5;
  padding-bottom: 5em;

  &:before {
    background: linear-gradient(0deg, rgba($color-gray, 0) 0%, $color-gray 5em);
  }
}