@import "_settings-color";
@import "_settings-fonts";

#tarteaucitronRoot {
  #tarteaucitronAlertBig {
    background-color: $color-main-dark !important;
  }

  #tarteaucitronDisclaimerAlert {
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 0.5em !important;
    display: inline-block !important;
  }

  @mixin tarteaucitronButton {
    font-family: "Poppins", sans-serif !important;
    font-size: 1em !important;
    padding: 0.5em !important;
  }

  #tarteaucitronPercentage,
  #tarteaucitronAlertBig #tarteaucitronPersonalize {
    @include tarteaucitronButton;
    background: $color-secondary !important;
    color: $color-main-dark !important;
  }

  #tarteaucitronPercentage {
    padding: 0 !important;
  }

  #tarteaucitronAlertBig #tarteaucitronCloseAlert {
    @include tarteaucitronButton;
    //font-size: 0.9em !important;
    color: $color-main !important;
    border: none !important;
  }
}