@import "_settings-color";

.container-card-pricing {
  background-color: white;
  box-shadow: $box-shadow-main-dark;
  color: $color-main-dark;
  margin-top: 40px;
  margin-bottom: 40px;

  .box-title {
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    color: $color-secondary;
  }

  .box-pricing {
    color: $color-secondary;
    padding: 1em 0 0;

    & > * {
      margin: 0;
    }

    .pricing-container {
      font-size: 2.5em;

      @media all and (min-width: $break-s) {
        font-size: 3em;
      }

      & > *:first-child {
        margin-top: 0;
      }
      & > *:last-child {
        margin-bottom: 0;
      }
    }

    .text-pricing {
      font-weight: 700;
    }
  }
}